<div class="w-full flex flex-row justify-center">
  <a
    routerLink="/content/39-kreatywna-noc-zakupow-unicorn"
    class="relative flex w-full justify-center max-w-100 sm:max-w-240 md:max-w-480 aspect-video md:aspect-[3.2/1]">
    <img
      class="flex w-full max-w-100 aspect-video sm:hidden"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/488.jpg?aspect_ratio=16:9&quality=75"
      alt="Noc Zakupów - UNICORN "
      fill
      priority />
    <img
      class="hidden w-full max-w-240 aspect-video sm:flex md:hidden"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/487.jpg?aspect_ratio=16:9&quality=75"
      alt="Noc Zakupów - UNICORN "
      fill
      priority />
    <img
      class="hidden w-full max-w-480 aspect-[3.2/1] md:flex"
      ngSrc="https://manzuko.b-cdn.net/assets/images/b/486.jpg?aspect_ratio=32:10&quality=75"
      alt="Noc Zakupów - UNICORN "
      fill
      priority />
  </a>
</div>
